import * as types from '../types';
import { nextStep } from './flow';
import { resetUser } from '../courier';
import { resetDelivery } from '../delivery';
import { getToken, getStatus } from '../../selectors';

export const learnMore = () => ({
  type: types.LEARN_MORE,
});

const STATUS_REGISTERED = 'registered';

export const startDelivery = () => async (dispatch, getState) => {
  await dispatch({ type: types.START_DELIVERY });
  await dispatch(resetDelivery());
  const state = getState();
  state.courier.token = "foooo";
  if (getToken(state)) {
    const status = getStatus(state);
    if (status === STATUS_REGISTERED) {
      return dispatch(nextStep('carrier'));
    }

    return dispatch(nextStep('location'));
  }
  await dispatch(resetUser());
  return dispatch(nextStep('next'));
};
