function success(boxNumber, citiboxId, deliveryId) {
  const citiboxCourierSDK = window.CitiboxCourierSDK;

  if (citiboxCourierSDK) {
    citiboxCourierSDK.onSuccess(boxNumber, citiboxId, deliveryId);
  }

  if (window.webkit) {
    window.webkit.messageHandlers.success.postMessage({
        boxNumber,
        citiboxId,
        deliveryId,
      })
  }
}

function error(errorCode) {
  const citiboxCourierSDK = window.CitiboxCourierSDK;
  if (citiboxCourierSDK) {
    citiboxCourierSDK.onError(errorCode);
  }

  if (window.webkit) {
    window.webkit.messageHandlers.error.postMessage({
      code: errorCode,
    })
  }


}

function fail(failureCode) {
  const citiboxCourierSDK = window.CitiboxCourierSDK;
  if (citiboxCourierSDK) {
    citiboxCourierSDK.onFail(failureCode);
  }
  if (window.webkit) {
    window.webkit.messageHandlers.fail.postMessage({
      code: failureCode,
    })
  }
}

function cancel(cancelCode) {
  const citiboxCourierSDK = window.CitiboxCourierSDK;
  if (citiboxCourierSDK) {
    citiboxCourierSDK.onCancel(cancelCode);
  }

  if (window.webkit) {
    window.webkit.messageHandlers.cancel.postMessage({
      code: cancelCode,
    })
  }
}

export const CitiboxMobileSDK = {
  cancel,
  error,
  fail,
  success,
};
