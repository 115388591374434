export const DELIVERY_SET_LOCK_ID = 'DELIVERY_SET_LOCK_ID';
export const DELIVERY_SET_LOCATION_ID = 'DELIVERY_SET_LOCATION_ID';
export const DELIVERY_SET_TRACKING = 'DELIVERY_SET_TRACKING';
export const DELIVERY_SET_TRACKING_IMAGE = 'DELIVERY_SET_TRACKING_IMAGE';
export const DELIVERY_SET_RECIPIENT = 'DELIVERY_SET_RECIPIENT';
export const DELIVERY_SET_ID = 'DELIVERY_SET_ID';
export const DELIVERY_SET_CONSUMER_NAME = 'DELIVERY_SET_CONSUMER_NAME';
export const DELIVERY_SET_CONSUMER_PHONE_NUMBER = 'DELIVERY_SET_CONSUMER_PHONE_NUMBER';
export const DELIVERY_RESET = 'DELIVERY_RESET';
export const DELIVERY_RESET_WITH_LOCATION = 'DELIVERY_RESET_WITH_LOCATION';
export const DEEPLINK_START_DELIVERY = 'DELIVERY_START_START_DELIVERY';
export const DELIVERY_SET_BOX_OPERATIONAL_DATA = 'DELIVERY_SET_BOX_OPERATIONAL_DATA';
