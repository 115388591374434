/* eslint-disable camelcase */

import uniqBy from 'lodash-es/uniqBy';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { schemas } from '../api';

import { locationsSelector, usersSelector, deliveriesSelector } from './entities';

export const getDelivery = state => state.delivery;

export const getDeliveryId = state => getDelivery(state).id || '';
export const getLockId = state => getDelivery(state).lockId || '';
export const getConsumerName = state => getDelivery(state).consumerName || '';
export const getConsumerPhone = state => getDelivery(state).consumerPhone || '';
export const getDeliveryRecipient = state => getDelivery(state).recipient || {};
export const getTrackingImage = state => getDelivery(state).trackingImage || '';
export const getTracking = state => getDelivery(state).tracking || '';
const deliveryLocationIdSelector = state => getDelivery(state).locationId;

const getDeliveryLocation = createSelector(
  [deliveryLocationIdSelector, locationsSelector, usersSelector],
  (deliveryLocationId, locations, users) =>
    deliveryLocationId
      ? denormalize(deliveryLocationId, schemas.LOCATION, { locations, users })
      : {},
);

export const isDeliveryLocationAvailable = state => getDeliveryLocation(state).available_boxes;
export const getDeliveryLocationBoxesInfo = state => getDeliveryLocation(state).boxes_info;
export const getDeliveryLocationAddress = state => getDeliveryLocation(state).address;
export const getDeliveryLocationId = state => getDeliveryLocation(state).id;
export const getLocationUsers = state => {
  const users = getDeliveryLocation(state).users || [];
  return uniqBy(users, 'phone'); // a better strategy should be used in /reducers/entieties.js to merge the entities and avoid duplications in the state
};
export const deliveryIdSelector = state => getDelivery(state).id;

// const getDeliveryEntity = createSelector(
//   [deliveryIdSelector, deliveriesSelector],
//   (deliveryId, deliveries) =>
//     deliveryId ? denormalize(deliveryId, schemas.DELIVERY, { deliveries }) : {},
// );

const getDeliveryEntityAttributes = state => {
  if (state.delivery.attributes) {
    return state.delivery.attributes;
  }

  return {};
}

export const getBox = state => getDeliveryEntityAttributes(state).box_number || '';
export const getSize = state => getDeliveryEntityAttributes(state).size || '';
export const getCode = state => getDeliveryEntityAttributes(state).opening_code || '';
