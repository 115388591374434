import * as api from '../api';
import * as types from '../types';
import { nextStep, startLoading, stopLoading, createTimeout } from './flow';
import {
  setTracking,
  setLockId,
  setTrackingImage,
  setDeliveryBoxOperationalData,
} from '../delivery';
import { updateActiveScanning, setAppError, setAppMessage } from '../app';
import { updateIsScanSuccessful, updateIsScanInvalid } from '../ui';
import { getLocationId, getDelivery } from '../../selectors';

const SCANNER_SUCCESS_TIMEOUT = 1000;
const SCANNER_ERROR_TIMEOUT = 1000;

export const cameraPermissionDenied = () => dispatch => {
  dispatch({
    type: types.DENY_CAMERA_QR,
  });
};

export const manualEntryQR = () => ({
  type: types.MANUAL_ENTRY,
});

export const cameraEntryQR = () => ({
  type: types.CAMERA_ENTRY,
});

const fetchLocationsError = apiResponse => async dispatch => {
  const { response: { code } = {} } = apiResponse.payload;
  if (code === 'unsupported_lock') {
    await dispatch(nextStep('regularDelivery'));
    await dispatch(setLockId({ lockId: '' }));
  } else {
    throw new Error(apiResponse.payload);
  }
};

const fetchLocationsByLabelCode = ({ labelCode, inputType }) => async (dispatch, getState) => {
  const deliveryData = getDelivery(getState());
  const apiResponse = await dispatch(
    api.postDeliveryParcel({
      labelCode,
      tracking: deliveryData.tracking,
      phone: deliveryData.phone,
    }),
  );

  if (apiResponse.error) {
    await dispatch(fetchLocationsError(apiResponse));
    return;
  }

  // await dispatch(setLockId({ lockId: '' }));

  await dispatch(
    setDeliveryBoxOperationalData({
      citibox_id: apiResponse.payload.citibox_id,
      id: apiResponse.payload.delivery_id,
      attributes: {
        box_number: apiResponse.payload.box_number,
        opening_code: apiResponse.payload.opening_code,
      },
    }),
  );

  if (inputType !== 'scan') {
    await dispatch(nextStep('next'));
    return;
  }
  await dispatch(updateIsScanSuccessful(true));
  createTimeout(
    'qrScannerTimeout',
    async () => {
      await dispatch(updateIsScanSuccessful(false));
      await dispatch(nextStep('next'));
    },
    SCANNER_SUCCESS_TIMEOUT,
  );
};

const fetchLocations = ({ labelCode, inputType }) => async dispatch => {
  const loadingProcess = 'FLOW_LOCATION_NEXT';
  await dispatch(
    startLoading({
      action: loadingProcess,
      extras: {
        label: inputType,
      },
    }),
  );

  try {
    await dispatch(fetchLocationsByLabelCode({ labelCode, inputType }));
  } catch (err) {
    if (inputType !== 'auto') {
      await dispatch(updateIsScanInvalid(true));
      createTimeout(
        'qrScannerTimeout',
        async () => {
          await dispatch(updateIsScanInvalid(false));
          await dispatch(updateActiveScanning('QR'));
        },
        SCANNER_ERROR_TIMEOUT,
      );
    }
    await dispatch(setAppError(err));
  }

  return dispatch(stopLoading(loadingProcess));
};

export const lockIdScan = labelCode => async dispatch => {
  await dispatch(updateActiveScanning(''));
  dispatch({
    type: types.SCAN_QR,
    payload: labelCode,
  });
  return dispatch(
    fetchLocations({
      labelCode,
      inputType: 'scan',
    }),
  );
};

export const lockIdInput = labelCode => async dispatch => {
  dispatch({
    type: types.MANUAL_INPUT_QR,
    payload: labelCode,
  });
  dispatch(setLockId({ lockId: labelCode }));
  return dispatch(
    fetchLocations({
      labelCode,
      inputType: 'manual',
    }),
  );
};

export const lockIdAuto = () => async (dispatch, getState) => {
  const locationId = getLocationId(getState());
  dispatch({
    type: types.AUTO_QR,
    payload: locationId,
  });
  return dispatch(
    fetchLocations({
      locationId,
      inputType: 'auto',
    }),
  );
};

const isTrackingCodeALockerCode = (trackingCode = '') => async dispatch => {
  if (trackingCode && trackingCode.length > 6) {
    return false;
  }

  try {
    const apiResponse = await dispatch(api.fetchLocations({ labelCode: trackingCode }));
    return !apiResponse.error;
  } catch (error) {
    return false;
  }
};

export const changeTrackingCode = tracking => async dispatch => {
  const isALockerCode = await dispatch(isTrackingCodeALockerCode(tracking));
  if (isALockerCode) {
    await dispatch(setAppMessage('please_scan_the_package'));
    await dispatch(updateIsScanInvalid(true));
    createTimeout(
      'qrScannerTimeout',
      async () => {
        await dispatch(updateIsScanInvalid(false));
      },
      SCANNER_ERROR_TIMEOUT,
    );
  } else {
    await dispatch(updateActiveScanning(''));
    const loadingProcess = 'FLOW_TRAKING_NEXT';
    await dispatch(
      startLoading({
        action: loadingProcess,
        extras: {
          label: 'scan',
        },
      }),
    );

    await dispatch(stopLoading(loadingProcess));
    await dispatch(setTracking({ tracking }));
    await dispatch(updateIsScanSuccessful(true));
    createTimeout(
      'barcodeScannerTimeout',
      async () => {
        await dispatch(updateIsScanSuccessful(false));
        await dispatch(nextStep('next'));
      },
      SCANNER_SUCCESS_TIMEOUT,
    );
  }
};

export const submitTrackingFile = file => async dispatch => {
  const loadingProcess = 'FLOW_TRAKING_NEXT';
  await dispatch(
    startLoading({
      action: loadingProcess,
      extras: {
        label: 'file',
      },
    }),
  );
  const localImageUrl = window.URL.createObjectURL(file);
  dispatch(setTrackingImage({ trackingImage: localImageUrl }));
  dispatch({
    type: types.TAKE_PICTURE,
  });
  await dispatch(nextStep('next'));
  return dispatch(stopLoading(loadingProcess));
};
