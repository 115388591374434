import * as types from '../types';
import { nextStep } from './flow';

export const deepLinkStartDelivery = payload => async dispatch => {
  console.log('DeepLinklStartDelivery');
  await dispatch({
    type: types.DEEPLINK_START_DELIVERY,
    payload,
  });

  await dispatch({
    type: types.SET_TOKEN,
    payload: {
      token: { access_token: payload.accessToken },
    },
  });

  return dispatch(nextStep('location'));
};
