import * as types from './types';

export const setLockId = payload => ({
  type: types.DELIVERY_SET_LOCK_ID,
  payload,
});

export const setTracking = payload => ({
  type: types.DELIVERY_SET_TRACKING,
  payload,
});

export const setTrackingImage = payload => ({
  type: types.DELIVERY_SET_TRACKING_IMAGE,
  payload,
});

export const setLocationId = payload => ({
  type: types.DELIVERY_SET_LOCATION_ID,
  payload,
});

export const setConsumerName = payload => ({
  type: types.DELIVERY_SET_CONSUMER_NAME,
  payload,
});

export const setConsumerPhone = payload => ({
  type: types.DELIVERY_SET_CONSUMER_PHONE_NUMBER,
  payload,
});

export const setRecipient = payload => ({
  type: types.DELIVERY_SET_RECIPIENT,
  payload,
});

export const setDeliveryId = payload => ({
  type: types.DELIVERY_SET_ID,
  payload,
});

export const resetDelivery = () => ({
  type: types.DELIVERY_RESET,
});

export const resetDeliveryWithLocation = locationId => ({
  type: types.DELIVERY_RESET_WITH_LOCATION,
  payload: { locationId },
});

export const deeplinkStartDelivery = payload => ({
  type: types.DEEPLINK_START_DELIVERY,
  payload: {
    ...payload,
  },
});

export const setDeliveryBoxOperationalData = payload => ({
  type: types.DELIVERY_SET_BOX_OPERATIONAL_DATA,
  payload: {
    ...payload,
  },
});
