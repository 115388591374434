import { normalize } from 'normalizr';

export { schemas } from './schemas';

window.process = window.process || {};
window.process.env = window.process.env || {};
if (!window.process.env.API_ROOT) {
  window.process.env.API_ROOT = `https://${window.process.env.CITIBOX_API_HOST}`;
} else {
  throw new Error('CITIBOX_API_HOST is not defined');
}

export const { API_ROOT, API_KEY } = window.process.env;

export const normalizeJSON = schema => json => ({
  ...normalize(json.results ? json.results : json, schema),
  nextPageUrl: json.next,
});

export const getResponse = (action, state, res) => {
  const contentType = res.headers.get('Content-Type');
  if (contentType && contentType.indexOf('json') !== -1) {
    return res.json();
  }
  return res;
};

export const normalizeResponse = schema => (action, state, res) =>
  getResponse(action, state, res).then(normalizeJSON(schema));
